/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
*/
import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect, useLocation, useRouteMatch } from "react-router-dom";// 
import { shallowEqual, useSelector } from "react-redux";

import { Layout, LayoutSplashScreen } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import UnderConstruction from "./modules/ErrorsExamples/UnderConstruction";
import Maintenance from './modules/ErrorsExamples/Maintenance'
import Forbidden from "./modules/ErrorsExamples/Forbidden";

const ResetPasswordPage = lazy(() => import('./modules/Auth/pages/ResetPassword'));

const WHITE_LIST_SIDEBAR = ['/item-editor/:id', '/task-editor/:id', '/form-editor/:id']

const WHITE_LIST_CHAT = [
	'/item-editor/:id',
	'/task-editor/:id',
	'/form-editor/:id',
	'/schedule-editor/:id',
	'/data-connector/advance',
	'/data-connector/advance/:id',
	'/data-connector/simple',
	'/data-connector/simple/:id',
	'/referencedata/:id',
	'/report-designer',
	'/robotic-automation/add',
	'/robotic-automation/:id',
]

export function Routes() {
	const { isAuthorized } = useSelector(
		({ auth }) => ({
			isAuthorized: auth.authToken != null,
		}),
		shallowEqual
	);

	const location = useLocation();
	const returnUrl = `${location.pathname}${location.search}${location.hash}`;
	// eslint-disable-next-line
	const redirectUrl = location.pathname === "/" || location.pathname === "/logout" ? '/authentication/login' : `/authentication/login?ReturnUrl=${encodeURI(returnUrl)}`;

	const redirectTo = new URLSearchParams(window.location.search).get('ReturnUrl');

	const authLayoutBase = () => {
		const matchRoute = useRouteMatch(WHITE_LIST_SIDEBAR)
		if (!isAuthorized) {
			return <Redirect to={redirectUrl} />
		} else if (matchRoute !== null) {
			return <BasePage />
		} else {
			return (
				<Layout>
					<BasePage />
				</Layout>
			)
		}
	}

	return (
		<Suspense fallback={<LayoutSplashScreen />}>

			<Switch>
				<Route path="/reset-password">
					<ResetPasswordPage isAuthorized={isAuthorized} />
				</Route>
				<Route path="/maintenance" component={Maintenance} />
				<Route path="/403" component={Forbidden} />

				{!isAuthorized ? (
					/* Render auth page when user at `/auth` and not authorized */
					<Route>
						<AuthPage redirectUrl={redirectUrl} />
					</Route>
				) : (
					/* Otherwise redirect to root page (`/`) */
					<Redirect from="/authentication" to={redirectTo ?? "/"} />
				)}

				<Route path="/error" component={UnderConstruction} />
				<Route path="/logout" component={Logout} />

				{authLayoutBase()}
			</Switch>
		</Suspense>
	);
}